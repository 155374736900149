<template>
	<article class="quiz__block multiple-chocies">
		<h2 class="quiz__block--title">Question #{{ index }}</h2>
		<p>{{ content }}</p>
		<v-checkbox
			v-model="answer"
			v-for="option in responses"
			:key="option.id"
			:label="option.content"
			:value="option.id"
		>
		</v-checkbox>
	</article>
</template>

<script>
export default {
	name: "QuizMultipleChoices",
	props: {
		content: {
			type: String,
			require: true
		},
		responses: {
			type: Array,
			require: true
		},
		index: {
			type: Number,
			require: true
		},
		selectedAnswers: {
			type: Object,
			require: true
		},
		questionId: {
			type: Number,
			require: true
		}
	},
	computed: {
		answer: {
			get() {
				if (this.selectedAnswers[this.questionId] === undefined)
					return [];
				return this.selectedAnswers[this.questionId];
			},
			set(val) {
				this.$emit("addSelectedAnswer", {
					id: this.questionId,
					value: val
				});
			}
		}
	}
};
</script>
